import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';

function MarketClock(props) {
  const [date, setDate] = useState(DateTime.local({zone: 'Pacific/Auckland'}));

  useEffect(() => {
    const timerID = setInterval(() => {
      tick();
    }, 1000);

    return () => clearInterval(timerID);
  });

  function tick() {
    setDate(DateTime.local({zone: 'Pacific/Auckland'}));
  }

  function getTradingPeriod(date) {
    const trading_period = Math.trunc( (date - date.startOf('day') + (30 * 60 * 1000)) / (30 * 60 * 1000) );
    return trading_period;
  }

  return (
    <div style={{
      marginRight: '20px'
    }}>
      {date.toLocaleString({...DateTime.DATETIME_MED, weekday: 'long' })} [{getTradingPeriod(date)}]
    </div>
  );

}


export default MarketClock;
