import { ResponsiveLine } from '@nivo/line';
import { line } from 'd3';


function NdsiChart({ data, chartTitle, customLabels, showBottomAxis, miny, maxy, activeLine, setActiveLine }) {

  // Calculate first index of each month group for custom tick labels.
  const grouped = {};
  for (const [k, v] of Object.entries(customLabels)) {
    if (!grouped[v]) grouped[v] = +k;
  }
  const monthTicks = Object.values(grouped);

  const CustomLayer = ({ series, lineGenerator, xScale, yScale }) => {

    lineGenerator
      .defined(d => d.y != null)

    return series.map(({ id, data, color }) => (
      <path
        key={id}
        d={lineGenerator(
          data.map(d => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y),
          }))
        )}
        fill="none"
        stroke={id === activeLine && color !== 'red' && color !== 'blue' ? 'black' : color}
        strokeWidth={id === activeLine ? 5 : 2}
        strokeOpacity={id === activeLine ? 1 : 0.5}
        onMouseEnter={() => setActiveLine(id)}
        onMouseLeave={() => setActiveLine(null)}
      />
    ))
  };

  const TitleLayer = ({ innerWidth, title }) => {
    return (
        <text
            x={innerWidth / 2}
            y={-15} // You can adjust this to position your title appropriately
            textAnchor="middle"
            dominantBaseline="middle"
            style={{ fontSize: '16px', fontWeight: 'bold' }}
        >
          {title}
        </text>
    );
  };

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: 'point', }}
      yScale={{ type: 'linear', min: miny, max: maxy, stacked: false, reverse: false }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={showBottomAxis ? {
        orient: 'bottom',
        tickValues: monthTicks,
        format: value => customLabels[value],
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        // legend: 'Day of the year',
        legendOffset: 36,
        legendPosition: 'middle'
      } : null}
      axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'NDSI',
          legendOffset: -40,
          legendPosition: 'middle'
      }}
      colors={{datum: 'color'}}
      layers={['grid', 'axes', 'legends', CustomLayer, TitleLayer]}
      enablePoints={false}
      enableGridX={false}
      enableGridY={true}
      title={chartTitle}
    />
  )
};

export default NdsiChart;
