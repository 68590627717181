import { useEffect, useReducer } from 'react';
import * as d3 from 'd3';
import { DateTime } from 'luxon';
import { H3, HTMLSelect } from '@blueprintjs/core';
import { DateRangeInput2 } from "@blueprintjs/datetime2";

import { API } from 'aws-amplify';

import WindForecastChart from './WindForecastChart';
import WindForecastMultiSelect from './WindForecastMultiSelect';
import styles from './WindForecastChartContainer.module.css';


const initialState = {
  data: [],
  chartData: [],
  dateRange: [
    DateTime.local({zone: 'Pacific/Auckland'}).minus({days: 1}).toJSDate(),
    DateTime.local({zone: 'Pacific/Auckland'}).plus({days: 9}).toJSDate()
  ],
  models: ['predictwind-ensemble', 'xgboost-moving-average'],
  modelSelection: 'predictwind-ensemble',
  series: [],
  seriesSelection: ['ACTUAL', 'NRSS', 'NRSL', 'NZL_PWE', 'NZL_PWG', 'WDS'],
  vintages: [],
  vintageSelection: [],
  hasFetched: false
};


const ACTIONS = {
  LOAD_DATA: 'load-data',
  DATE_RANGE_CHANGE: 'date-range-change',
  MODEL_SELECTION_CHANGE: 'model-selection-change',
  SERIES_SELECTION_CHANGE: 'series-selection-change',
  VINTAGE_SELECTION_CHANGE: 'vintage-selection-change'
};


const windReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.LOAD_DATA:
      const series = [...new Set(action.payload.map(d => d.series_name))];
      // On initial load state.hasFetched is false, return the default company/station selection
      if (!state.hasFetched) {
        return {
          ...state,
          data: action.payload,
          series: series,
          hasFetched: true,
          chartData: getChartData(action.payload, state.modelSelection, state.seriesSelection)
        };
      }
      // Otherwise, we have done the initial fetch and want to preserve company/station selection
      else {
        return {
          ...state,
          data: action.data,
          chartData: getChartData(action.payload, state.modelSelection, state.seriesSelection)
        };
      }

    case ACTIONS.DATE_RANGE_CHANGE:
      // Changing the dateRange will cause LOAD_DATA to dispatch.
      return {
        ...state,
        dateRange: action.payload
      };

    case ACTIONS.MODEL_SELECTION_CHANGE:
      // TODO No effect
      return {
        ...state
      };

    case ACTIONS.SERIES_SELECTION_CHANGE:
      return {
        ...state,
        seriesSelection: action.payload,
        chartData: getChartData(state.data, state.modelSelection, action.payload)
      };

    default:
      throw new Error();
  }
};


// Current implementation allows for viewing one model at a time only so
// modelSelection is currently unused.
const getChartData = (data, modelSelection, seriesSelection) => {

  const filteredData = data.filter(d => seriesSelection.indexOf(d.series_name) !== -1);

  let nivoData = filteredData.map(d => {
    return {
      series_name: d.series_name,
      trading_date: d.trading_date,
      trading_period: d.trading_period,
      x: DateTime.fromISO(d.period_timestamp).setZone("Pacific/Auckland").toISO({ suppressMilliseconds: true, includeOffset: false }),
      y: d.y
    }
  });

  nivoData = d3.group(nivoData, d => d.series_name);

  // Set the colorScale domain
  let domain = new Set(['ACTUAL', 'NRSS', 'NRSL', 'NZL_PWE', 'NZL_PWG']);
  Array.from(nivoData.keys()).forEach(d => domain.add(d));
  let colorScale = d3.scaleOrdinal(d3.schemeCategory10).domain(domain);

  nivoData = Array.from(nivoData, ([k, v]) => ({
    id: k,
    color: colorScale(k),
    data: v
  }));

  return nivoData;

};


function WindForecastChartContainer(props) {

  const [state, dispatch] = useReducer(windReducer, initialState);
  const { chartData, dateRange, models, modelSelection, series, seriesSelection, vintages, vintageSelection} = state;

  // Fetch data.
  useEffect(() => {
    const [fromDate, toDate] = dateRange;

    document.title = `Wind Forecast`;

    async function fetchSystemOperatorForecast() {
      const resp = await API.get('SparcAPI', '/wind/system_operator_wind_forecast', {
        queryStringParameters: {
          from_date: DateTime.fromJSDate(fromDate).toISODate(),
          to_date: DateTime.fromJSDate(toDate).toISODate()
        }
      });
      return resp;
    }

    async function fetchPredictWindForecast() {
      const resp = await API.get('SparcAPI', '/wind/emh_wind_forecast', {
        queryStringParameters: {
          model_collection: modelSelection,
          from_date: DateTime.fromJSDate(fromDate).toISODate(),
          to_date: DateTime.fromJSDate(toDate).toISODate()
        }
      });
      return resp;
    }

    async function fetchWindGenerationOrOffers() {
      const resp = await API.get('SparcAPI', '/wind/wind_generation_or_offers', {
        queryStringParameters: {
          from_date: DateTime.fromJSDate(fromDate).toISODate(),
          to_date: DateTime.fromJSDate(toDate).toISODate()
        }
      });
      return resp;
    }

    Promise.all([fetchSystemOperatorForecast(), fetchPredictWindForecast(), fetchWindGenerationOrOffers()])
    .then((values) => {
      // Log any errors. API Gateway will add errorMessage, errorType fields.
      values.forEach(d => {
        if ('errorMessage' in d) {
          throw new Error(d['errorMessage']);
        }
      });

      let [systemOperatorData, emhWindForecastData, generationOrOffersData] = values;

      // Series name should be upper case for all strings.

      systemOperatorData = systemOperatorData.map(d => {
        return {
          ...d,
          series_name: d.run_type.toUpperCase(),
          y: d.wits_forecast_mw
        }
      });

      emhWindForecastData = emhWindForecastData.map(d => {
        return {
          ...d,
          series_name: d.series_name.toUpperCase(),
          y: d.forecast_mw
        }
      });

      generationOrOffersData = generationOrOffersData.map(d => {
        return {
          ...d,
          series_name: 'ACTUAL',
          y: d.actual_mw
        }
      });

      const data = [...generationOrOffersData, ...systemOperatorData, ...emhWindForecastData];

      if (data.length === 0) throw new Error('Zero length data');

      dispatch({ type: ACTIONS.LOAD_DATA, payload: data });

    })
    .catch((err) => {
      console.log(err);
      alert('Error fetching data, check console log.')
    });

  }, [dateRange, modelSelection]);


  // For DateRangeInput
  const formatDate = (date) => DateTime.fromJSDate(date).toISODate();
  const parseDate = (str) => DateTime.fromISO(str);

  return (
    <div>

      <div className={styles.flex_container}>

        <div className={styles.flex_child}>
          <p>Date Range</p>
          <DateRangeInput2
            formatDate={formatDate}
            parseDate={parseDate}
            allowSingleDayRange={true}
            contiguousCalendarMonths={true}
            onChange={e => dispatch({ type: ACTIONS.DATE_RANGE_CHANGE, payload: e})}
            value={dateRange}
          />
        </div>

        <div className={styles.flex_child}>
          <p>Model Collection</p>
          <HTMLSelect
            options={models}
            onChange={e => dispatch({ type: ACTIONS.MODEL_SELECTION_CHANGE, payload: e.currentTarget.value })}
            value={modelSelection}
          />
        </div>

        <div className={styles.flex_child}>
          <p>Series</p>
          <WindForecastMultiSelect
            items={series}
            selectedItems={seriesSelection}
            dispatch={dispatch}
          />
        </div>

      </div>

      <div className={styles.charts}>
      <H3>Wind Forecast for model run TODO</H3>
        <div className={styles.chart}>
          <WindForecastChart data={chartData} />
        </div>
      </div>

    </div>
  );

}

export default WindForecastChartContainer;
