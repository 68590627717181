import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { API } from 'aws-amplify';
import { H3, HTMLSelect } from '@blueprintjs/core';
import { DateInput2 } from "@blueprintjs/datetime2";
import OfferStackTable from './OfferStackTable';
import styles from './OfferStackContainer.module.css';
import {DateInput} from "@blueprintjs/datetime";

function OfferStackContainer() {
  document.title = `Stacks`;
  const [data, setData] = useState(null);
  const [tradingDate, setTradingDate] = useState(DateTime.local().toISODate());
  const [runTimes, setRunTimes] = useState([]);
  const [selectedRunTime, setSelectedRunTime] = useState('');
  const [node, setNode] = useState('BEN2201');
  const [isFetchingRunTimes, setIsFetchingRunTimes] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const nodes = ['BEN2201', 'HAY2201'];

  useEffect(() => {
    async function fetchRunTimes() {
      if (!tradingDate || tradingDate === '') return;
      const response = await API.get('SparcAPI', `/wits/stack_run_times`, {
        queryStringParameters: { trading_date: tradingDate }
      });
      setRunTimes(response);
      if (response.length > 0) {
        setSelectedRunTime(response[0].run_time);
      } else {
        setSelectedRunTime('');
      }
    }
    setIsFetchingRunTimes(true);
    setSelectedRunTime('');
    fetchRunTimes().finally(() => setIsFetchingRunTimes(false));
  }, [tradingDate]);

  useEffect(() => {
    if (!selectedRunTime || selectedRunTime === '') return;
    async function fetchData() {
      const response = await API.get('SparcAPI', `/wits/offer_stack`, {
        queryStringParameters: {
          trading_date: tradingDate,
          run_time: selectedRunTime,
          ignore_mw: 5
        }
      });
      setData(response);
    }
    setIsLoading(true);
    fetchData().finally(() => setIsLoading(false));
  }, [selectedRunTime]);

  const handleDateChange = (newDate) => setTradingDate(newDate);
  const handleRunTimeChange = (event) => setSelectedRunTime(event.target.value);
  const handleNodeChange = (event) => setNode(event.target.value);

  const runTimeOptions = runTimes.map((rt, index) => {
    // Format the run_time for display in the 'Pacific/Auckland' timezone.
    const displayTime = DateTime.fromISO(rt.run_time)
      .setZone('Pacific/Auckland')
      .toFormat("yyyy-MM-dd HH:mm:ss");

    // Use the original run_time value for the option value, and the formatted time for the label.
    return (
      <option key={index} value={rt.run_time}>
        {`${rt.run_type} ${displayTime}`}
      </option>
    );
  });

  const jsDateFormatter = {
    formatDate: date => DateTime.fromJSDate(date).toISODate(),
    parseDate: str => DateTime.fromISO(str),
    placeholder: 'YYYY-MM-DD'
  };

  return (
    <div>
      <div className={styles.flex_container}>
        <div className={styles.flex_child}>
          <p>Trading Date</p>
          <DateInput2
            {...jsDateFormatter}
            value={tradingDate}
            defaultValue={tradingDate}
            onChange={handleDateChange}
          />
        </div>
        <div className={styles.flex_child}>
          <p>Stack Run Time</p>
          <HTMLSelect onChange={handleRunTimeChange} value={selectedRunTime}>
            {runTimeOptions}
          </HTMLSelect>
        </div>
        <div className={styles.flex_child}>
          <p>Node</p>
          <HTMLSelect options={nodes} onChange={handleNodeChange} value={node} />
        </div>
      </div>
      <div style={{height: "2000px", overflow: "scroll"}}>
        {data && <OfferStackTable data={data[node]} isLoading={isLoading} isFetchingRunTimes={isFetchingRunTimes} />}
      </div>
    </div>
  );
}

export default OfferStackContainer;
