import React, { useEffect, useState } from 'react';
import { Cell, Column, Table2 as Table, RenderMode, TableLoadingOption } from '@blueprintjs/table';

function OfferStackTable({ data, isLoading, isFetchingRunTimes }) {
    const [columnWidths, setColumnWidths] = useState([]);
    const [columns, setColumns] = useState([]);
    const [numRows, setNumRows] = useState([]);

    useEffect(() => {
        // Initialize or update column names from data
        if (!data || data.length === 0) {
            setColumnWidths([]);
            setColumns([]);
            setNumRows([]);
            return;
        }

        setNumRows(data.length);

        const priceKey = 'price';
        const otherKeys = Object.keys(data[0])
            .filter(key => key !== 'node' && key !== priceKey)
            .map(key => parseInt(key, 10)) // Convert keys to integers for sorting
            .sort((a, b) => a - b) // Perform numerical sort
            .map(key => key.toString()); // Convert sorted keys back to strings

        const columnNames = [priceKey, ...otherKeys]; // Ensure 'price' is the first column

        // Define column widths
        const defaultColumnWidth = 50; // Default width for each column
        const initialColumnWidths = columnNames.map(() => defaultColumnWidth);
        setColumnWidths(initialColumnWidths);

        const cellRenderer = (rowIndex, colIndex) => {
            const rowData = data[rowIndex];
            if (!rowData) return <Cell>-</Cell>; // Or any placeholder to indicate missing data

            const cellValue = rowData[columnNames[colIndex]];
            return <Cell>{cellValue !== undefined ? cellValue : '-'}</Cell>;
        };

        // Create columns
        const newColumns = columnNames.map((columnName, index) => ({
            key: index,
            name: columnName,
            cellRenderer: (rowIndex) => cellRenderer(rowIndex, index)
        }));

        setColumns(newColumns);

    }, [data, isLoading]); // Re-run when `data` changes

    if (isFetchingRunTimes) {
        return <div>Fetching runtimes...</div>;
    }
    if (isLoading) {
        return <div>Loading data...</div>;
    }

    if (!data || data.length === 0 || columns.length === 0) {
        return <div>No data available.</div>;
    }

    return (

        <Table
            numRows={numRows}
            renderMode={RenderMode.NONE}
            columnWidths={columnWidths}
        >
            {columns.map(({ key, name, cellRenderer }) => (
                <Column key={key} name={name} cellRenderer={cellRenderer} />
            ))}
        </Table>
    );
}

export default OfferStackTable;
