import React from 'react';

import { Button, MenuItem } from '@blueprintjs/core';
import { MultiSelect2 } from '@blueprintjs/select';


export default function GenerationStationMultiSelect({ items, selectedItems, dispatch }) {

  const LocalGenerationStationMultiSelect = MultiSelect2.ofType();

  const itemRenderer = (item, { handleClick, modifiers }) => {
    return (
      <MenuItem
        active={modifiers.active}
        icon={isSelected(item) ? 'tick' : 'blank'}
        key={item}
        // label={item}
        disabled={modifiers.disabled}
        onClick={handleClick}
        text={item}
      />
    );
  };

  const renderTag = (item) => {
    return `${item}`;
  };

  const getSelectedIndex = (item) => {
    return selectedItems.indexOf(item);
  };

  const isSelected = (item) => {
    return getSelectedIndex(item) !== -1;
  };

  const selectItem = (item) => {
    const selected = [...selectedItems, item];
    dispatch({ type: 'stations', stationSelection: selected });
  };

  const deselect = (index) => {
    const selected = selectedItems.filter((d, i) => i !== index);
    dispatch({ type: 'stations', stationSelection: selected });
  };

  const handleSelect = (item) => {
    if (!isSelected(item)) {
      selectItem(item);
    } else {
      deselect(getSelectedIndex(item));
    }
  };

  const handleTagRemove = (_tag, index) => {
    deselect(index);
  };

  const handleClear = () => dispatch({ type: 'stations', stationSelection: [] });
  const clearButton = items.length > 0 ? <Button icon='cross' minimal={true} onClick={handleClear} /> : undefined;

  return (
    <LocalGenerationStationMultiSelect
      items={items}
      itemRenderer={itemRenderer}
      onItemSelect={handleSelect}
      popoverProps={{ minimal: true }}
      tagRenderer={renderTag}
      tagInputProps={{
        onRemove: handleTagRemove,
        rightElement: clearButton,
      }}
      selectedItems={selectedItems}
    />
  );

}