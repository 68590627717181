// DEPRECATED - leaving this here to refactor for when building Span file for Karl


import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Blockquote, Button, H3, Pre, Spinner } from '@blueprintjs/core';
import { DateInput2 } from "@blueprintjs/datetime2";

import { API } from 'aws-amplify';

import styles from './AsxPortfolioMarginContainer.module.css';


function DownloadLink({ path, entity, account, priceDate, positionDate, performMonthRoll, tagText, setIsLoading }) {

  const handleClick = (e) => {
    e.preventDefault();

    const init = {
      response: true,
      responseType: 'blob',
      queryStringParameters: {
        entity: entity,
        account: account,
        price_date: priceDate,
        position_date: positionDate,
        perform_month_roll: performMonthRoll,
      }
    };

    setIsLoading(true);

    API
      .get('SparcAPI', path, init)
      .then(response => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;

        const contentDisposition = response.headers['content-disposition'];
        let filename = 'default-spanfile.spn';
        if (contentDisposition) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(contentDisposition);
          if (matches !== null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        };

        link.setAttribute('download', filename);

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

      })
      .catch(error => {
        console.log(error.response.data);
        alert("Error generating SPAN file, no settlement price data for this date?")
      }).finally(() => { setIsLoading(false); });

  };

  return (
    <div className={styles.span_download_link}>
      <a href={path} onClick={handleClick}>{tagText}</a>
    </div>

  );

}


function ReconButton({ entity, setIsLoading }) {

  const [error, setError] = useState(null);

  const handleButtonClick = async () => {
    setIsLoading(true);

    const resp = await API.get('SparcAPI', '/recon/invoke_lambda', {
      queryStringParameters: {
        entity: entity,
      }
    }).catch(error => {
      setError(error.response.data.error + error.response.data.stack_trace);
    }).finally(() => { setIsLoading(false); });
  }

  const tagTextMap = {
    'MBL': 'Macquarie',
    'OMF': 'Jarden',
    'AE': 'Aotearoa Energy',
  }

  return (
      <div>
          <Button onClick={handleButtonClick} className={styles.fixed_width_btn}>{tagTextMap[entity]}</Button>
          {error && <div>Error: {error}</div>}
      </div>
  );
}



function AsxPortfolioMarginContainer(props) {
  document.title = `Risk Tools`;

  const [priceDate, setPriceDate] = useState(DateTime.local({zone: 'Pacific/Auckland'}).minus({days: 0}).toISODate());
  const [positionDate, setPositionDate] = useState(DateTime.local({zone: 'Pacific/Auckland'}).minus({days: 0}).toISODate());
  const [latestSpanFilename, setLatestSpanFilename] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // For DateInput
  const formatDate = (date) => { return DateTime.fromJSDate(date).toISODate(); }
  const parseDate = (str) => { DateTime.fromISO(str); }


  useEffect(() => {

    async function fetchData() {
      const resp = await API.get('SparcAPI', '/span/latest_settlement_date', {
        queryStringParameters: {
        }
      });

      const latestSettlementDate = DateTime.fromISO(resp.settlement_date).toISODate();

      setPriceDate(latestSettlementDate);
      setLatestSpanFilename(resp.latest_span_file);
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className={styles.flex_container}>

        <div className={styles.flex_child}>
          <p>Price Date</p>
          <DateInput2
            formatDate={formatDate}
            parseDate={parseDate}
            value={priceDate}
            onChange={setPriceDate}
          />
        </div>

        <div className={styles.flex_child}>
          <p>Position Date</p>
          <DateInput2
            formatDate={formatDate}
            parseDate={parseDate}
            value={positionDate}
            onChange={setPositionDate}
          />
        </div>

        {isLoading && <Spinner size={Spinner.SIZE_SMALL} />}
      </div>

      <div className={styles.container}>
        <H3>Latest ASX SPAN Input file: {latestSpanFilename}</H3>

        <DownloadLink
          path='/span/xml_portfolio'
          entity='BCNZF1'
          account='A4445056'
          priceDate={priceDate}
          positionDate={positionDate}
          performMonthRoll={false}
          tagText={`SPAN file for entity: BCNZF1 account: A4445056`}
          setIsLoading={setIsLoading}
        />

        <DownloadLink
          path='/span/xml_portfolio'
          entity='BMMNZ'
          account='A4445060'
          priceDate={priceDate}
          positionDate={positionDate}
          performMonthRoll={false}
          tagText={`SPAN file for entity: BMMNZ account: A4445060`}
          setIsLoading={setIsLoading}
        />

        <DownloadLink
          path='/span/xml_portfolio'
          entity='BMMNZ'
          account={['A4445060', 'A4445056']}
          priceDate={priceDate}
          positionDate={positionDate}
          performMonthRoll={false}
          tagText={`SPAN file for BOTH accounts`}
          setIsLoading={setIsLoading}
        />

        <DownloadLink
          path='/span/xml_portfolio'
          entity='BCNZF1'
          account='A4445056'
          priceDate={priceDate}
          positionDate={positionDate}
          performMonthRoll={true}
          tagText={`MONTH ROLL SPAN file for entity: BCNZF1 account: A4445056`}
          setIsLoading={setIsLoading}
        />

        <DownloadLink
          path='/span/xml_portfolio'
          entity='BMMNZ'
          account='A4445060'
          priceDate={priceDate}
          positionDate={positionDate}
          performMonthRoll={true}
          tagText={`MONTH ROLL SPAN file for entity: BMMNZ account: A4445060`}
          setIsLoading={setIsLoading}
        />

        <DownloadLink
          path='/span/xml_portfolio'
          entity='BMMNZ'
          account={['A4445060', 'A4445056']}
          priceDate={priceDate}
          positionDate={positionDate}
          performMonthRoll={true}
          tagText={`MONTH ROLL SPAN file for BOTH accounts`}
          setIsLoading={setIsLoading}
        />
        <Blockquote className={styles.block_quote_container}>
          <H3>Instructions</H3>

          SPAN files are generated by using the supplied ASX DayRiskParameter files and constructing a portfolio of
          positions for each entity/account combination. The portfolio can then be margined using the PC-SPAN tool.
          <br /><br />
          Price Date is the date for which the settlement prices are used to value the portfolio.
          <br />
          Position Date is the date for which the positions are valued.
          <br /><br />
          After you load the SPN file into the PC-SPAN tool, you should force re-calculation of the option implied volatilities: right click on the Point In Time (e.g., "Mon Sep 18 2018 Settle Final") and select "Calculate Option Implied Volatilities". Then create a What-If-Scenario, choose "Modify" and click "Calculate". This will calculate the contract values and risk arrays.
        </Blockquote>

        <H3>Run Reconciliation Reports</H3>

        <ReconButton entity='MBL' setIsLoading={setIsLoading} />
        <ReconButton entity='OMF' setIsLoading={setIsLoading} />
        <ReconButton entity='AE' setIsLoading={setIsLoading} />

      </div>

    </div>

  );

}

export default AsxPortfolioMarginContainer;