import { DateTime } from 'luxon';
import { ResponsiveLine } from '@nivo/line';

// the xScale domain is in the local time zone because JS dates.
const localNow = DateTime.local({ zone: 'Pacific/Auckland' }).setZone('local', { keepLocalTime: true }).toJSDate();

const refsLayer = ({ width, height, xScale, yScale }) => {
  const [xmin, xmax] = xScale.range();
  const rectWidth = xmax - xScale(localNow);
  const [ymin, ymax] = yScale.range();

  return (
    <g>
      <rect
        x={xScale(localNow)}
        width={rectWidth < 0 ? 0 : rectWidth}
        height={ymin - ymax}
        fill="rgba(211,211,211,0.45)"
      />
    </g>
  );
};


const zeroLineLayer = ({ width, height, xScale, yScale }) => {
  const [xmin, xmax] = xScale.range();
  const y0 = yScale(0);

  const lineStyle = {
    strokeWidth: 2,
    stroke: 'black'
  };

  return (
    <g>
      <line
        x1={xmin}
        y1={y0}
        x2={xmax}
        y2={y0}
        style={lineStyle}
      />
    </g>
  );
};


const tooltip = ({ slice }) => {
  const xLabel = slice.points.map(point => point.data.xFormatted)[0];
  const trading_period = slice.points.map(point => point.data.trading_period)[0];

  return (
    <div
      style={{
        background: 'white',
        padding: '9px 12px',
        border: '1px solid #ccc',
      }}
    >
      <div>{xLabel} [{trading_period}]</div>
      {slice.points.map(point => (
        <div
          key={point.id}
          style={{
            color: point.serieColor,
            padding: '3px 0',
          }}
        >
          <strong>{point.serieId}</strong> [{point.data.yFormatted}]
        </div>
      ))}
    </div>
  );
};


const styleById = {
  'delta_load_forecast_mw': {
    strokeDasharray: '3, 3',
    strokeWidth: 1,
  },
  'delta_wind_mw': {
    strokeDasharray: '3, 3',
    strokeWidth: 1,
  },
  'dnow': {
    strokeWidth: 2,
  },
  default: {
    strokeWidth: 1,
  },
};


const DashedLine = ({ series, lineGenerator, xScale, yScale }) => {
  return series.map(({ id, data, color }) => (
    <path
      key={id}
      d={lineGenerator(
        data.map(d => ({
          x: xScale(d.data.x),
          y: yScale(d.data.y),
        }))
      )}
      fill="none"
      stroke={color}
      style={styleById[id] || styleById.default}
    />
  ));
};


const Area = ({ series, areaGenerator, xScale, yScale }) => {
  return series.map(({ id, data, color }) => (
    <path
      key={id}
      d={areaGenerator(
        data.map(d => ({
          x: xScale(d.data.x),
          y: yScale(d.data.y),
        }))
      )}
      fill='red'
      fillOpacity={id === 'dnow' ? 0.25 : 0}
      style={{ mixBlendMode: "multiply", pointerEvents: "none" }}
    />
  ));
};


function WindDemandBalanceChart({ data, xlim }) {
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: 'time', format: "%Y-%m-%dT%H:%M:%S", useUTC: false }}
      xFormat="time:%Y-%m-%d %H:%M"
      yScale={{ type: 'linear', min: -xlim, max: xlim, stacked: false, reverse: false }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        format: '%a, %d/%m',
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -45,
        legendOffset: 36,
        legendPosition: 'middle'
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'MW',
        legendOffset: -40,
        legendPosition: 'middle'
      }}
      sliceTooltip={tooltip}
      enablePoints={false}
      enableSlices='x'
      colors={{datum: 'color'}}
      layers={['grid', refsLayer, zeroLineLayer, 'markers', 'axes', 'areas', 'crosshair', 'slices', 'points', 'mesh', 'legends', Area, DashedLine]}
      useMesh={true}
      animate={false}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
    />
  )
};

export default WindDemandBalanceChart;
