import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import { DateTime } from 'luxon';
import { H3 } from '@blueprintjs/core';
import { DateRangeInput } from "@blueprintjs/datetime";

import { API } from 'aws-amplify';

import GenerationChart from './GenerationChart';
import styles from './GenerationChartContainer.module.css';


function GenerationChartContainer({ apiUrl }) {

  const [data, setData] = useState({thermal: [], renewable: []});

  const [dateRange, setDateRange] = useState([
    DateTime.now().setZone("Pacific/Auckland").minus({days: 7}).toJSDate(),
    DateTime.now().setZone("Pacific/Auckland").minus({days: 0}).toJSDate()
  ]);

  useEffect(() => {
    const [fromDate, toDate] = dateRange;

    document.title = `Generation ${DateTime.fromJSDate(fromDate).toLocaleString()} to ${DateTime.fromJSDate(toDate).toLocaleString()}`;

    async function fetchData() {
      const resp = await API.get('SparcAPI', apiUrl, {
        queryStringParameters: {
          from_date: DateTime.fromJSDate(fromDate).toISODate(),
          to_date: DateTime.fromJSDate(toDate).toISODate()
        }
      });

      if (resp.length === 0) return;

      const data = chartData(resp);

      setData(data);
    }

    fetchData();
  }, [apiUrl, dateRange]);


  function chartData(resp) {

    // Parse the response into format for charts.
    let data = resp.map(d => {
      return {
        group_type: d.group_type,
        group_name: d.group_name,
        trading_period: d.trading_period,
        formatted_date: DateTime.fromISO(d.period_timestamp).toFormat('EEE dd-LLL HH:mm'),
        x: DateTime.fromISO(d.period_timestamp).setZone("Pacific/Auckland").toISO({ suppressMilliseconds: true, includeOffset: false }),
        y: d.mw
      };
    });

    data = d3.group(data, d => d.group_type, d => d.group_name);

    // group_type has 2 values, "Renewable" and "Thermal"

    const thermalData = Array.from(data.get("Thermal"), ([k, v]) => ({
      id: k,
      data: v
    }));

    const renewableData = Array.from(data.get("Renewable"), ([k, v]) => ({
      id: k,
      data: v
    }));

    const result = {
      thermal: thermalData,
      renewable: renewableData
    };

    return result;

  };


  return (
    <div>

      <div className={styles.flex_container}>
        <div className={styles.flex_child}>
          <p>Chart Date Range</p>
          <DateRangeInput
            formatDate={date => DateTime.fromJSDate(date).toISODate()}
            parseDate={str => DateTime.fromISO(str)}
            allowSingleDayRange={true}
            contiguousCalendarMonths={true}
            onChange={e => setDateRange(e)}
            value={dateRange}
          />
        </div>
      </div>

      <div className={styles.charts}>
        <H3>Thermal Generation</H3>
        <div className={styles.chart}>
          <GenerationChart data={data.thermal} />
        </div>
        <H3>Renewable Generation</H3>
        <div className={styles.chart}>
          <GenerationChart data={data.renewable} />
        </div>
      </div>

    </div>
  );

};

export default GenerationChartContainer;
