import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import { DateTime } from 'luxon';
import { H3 } from '@blueprintjs/core';

import { API } from 'aws-amplify';

import SystemLoadChart from './SystemLoadChart';
import styles from './SystemLoadChartContainer.module.css';


function SystemLoadChartContainer({ apiUrl }) {

  const [data, setData] = useState([]);

  const fromDate = DateTime.now().setZone("Pacific/Auckland").minus({days: 7}).toISODate();
  const toDate = DateTime.now().setZone("Pacific/Auckland").minus({days: 0}).toISODate();

  useEffect(() => {
    document.title = `Demand ${DateTime.fromISO(toDate).toLocaleString()}`;

    async function fetchActualLoadData() {
      const resp = await API.get('SparcAPI', '/ems/nz_load', {
        queryStringParameters: {
          from_date: fromDate,
          to_date: toDate
        }
      });
      return resp;
    }

    async function fetchForecastLoadData() {
      const resp = await API.get('SparcAPI', '/wits/nz_load_forecast', {
        queryStringParameters: {
          opr_date: toDate
        }
      });
      return resp;
    }

    Promise.all([fetchActualLoadData(), fetchForecastLoadData()]).then(([actData, fctData]) => {
      setData(chartData(actData, fctData));
    });

  }, [fromDate, toDate]);


  function chartData(actData, fctData) {
    // Parse the response into format for charts.

    actData = actData.map(d => {
      let key = `${d.trading_date}`;
      return {
        key: key,
        trading_date: d.trading_date,
        period_timestamp: DateTime.fromISO(d.period_timestamp).setZone("Pacific/Auckland").toISO({ suppressMilliseconds: true, includeOffset: false }),
        x: d.trading_period,
        y: d.mw
      };
    });

    fctData = fctData.map(d => {
      let key = `${d.forecast_vintage}`;
      return {
        key: key,
        forecast_vintage: d.forecast_vintage,
        trading_date: d.trading_date,
        period_timestamp: DateTime.fromISO(d.period_timestamp).setZone("Pacific/Auckland").toISO({ suppressMilliseconds: true, includeOffset: false }),
        run_time: DateTime.fromISO(d.run_time).setZone("Pacific/Auckland").toISO({ suppressMilliseconds: true, includeOffset: false }),
        x: d.trading_period,
        y: d.load_forecast_mw
      };
    });

    let data = [...actData, ...fctData];

    data = d3.group(data, d => d.key);

    // Set the colorScale domain to be all the forecast series, except for today
    // and set that color manually.
    let domain = Array.from(data.keys()).filter(d => d !== toDate);
    let colorScale = d3.scaleOrdinal(d3.schemeCategory10).domain(domain);

    const result = Array.from(data, ([k, v]) => ({
      id: k,
      color: k === toDate ? 'black' : colorScale(k),
      data: v
    }));

    return result;

  };


  return (
    <div className={styles.charts}>
      <H3>NZ System Load</H3>
      <div className={styles.chart}>
        <SystemLoadChart data={data} />
      </div>
    </div>
  );

};

export default SystemLoadChartContainer;
