import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import { DateTime } from 'luxon';
import { H3, HTMLSelect } from '@blueprintjs/core';

import { API } from 'aws-amplify';

import NdsiChart from './NdsiChart';
import styles from './NdsiChartContainer.module.css';


function NdsiChartContainer() {

  const [ndsiData, setNdsiData] = useState({cluster0: [], cluster1: [], cluster2: [], customLabels: []});
  const [cdefData, setCdefData] = useState({cluster0: [], cluster1: [], cluster2: [], customLabels: []});
  const [catchment, setCatchment] = useState('pukaki');
  const [isLoading, setIsLoading] = useState(false);
  const [movingAvgWindowSize, setMovingAvgWindowSize] = useState(21);
  const [activeLine, setActiveLine] = useState(2023);
  const [legendData, setLegendData] = useState([]);
  const [latestDate, setLatestDate] = useState(null);

  const catchments = ['clyde', 'hawea', 'pukaki', 'tekapo'];
  const movingAvgWindowSizes = [1, 3, 7, 14, 21];

  useEffect(() => {

    document.title = `NDSI Charts`;

    async function fetchData() {
      setIsLoading(true);

      const resp = await API.get('SparcAPI', '/ndsi/ndsi_by_catchment', {
        queryStringParameters: {
          catchment: catchment,
          moving_avg_window_size: movingAvgWindowSize
        }

      });

      if (resp.length === 0) return;

      const ndsiData = ndsiChartData(resp);
      const cdefData = cdefChartData(resp);

      setNdsiData(ndsiData);
      setCdefData(cdefData);

      setIsLoading(false);
    }

    fetchData();
  }, [catchment, movingAvgWindowSize]);


  function colorScale(currYear, prevYear, year) {
    if (year == currYear) return 'red';
    else if (year == prevYear) return 'blue';
    else return 'lightgrey';
  };


  function ndsiChartData(resp) {

    // Parse the response into format for charts.
    let data = resp.map(d => {
      return {
        pixel_date: d.pixel_date,
        catchment: d.catchment,
        cluster_label: d.cluster_label,
        year: d.year,
        hydro_year: d.hydro_year,
        x: d.yday,
        y: d.ndsi_ma
      };
    });

    data = data.filter(d => d.year > 2000);

    const miny = d3.min(data, d => d.y);
    const maxy = d3.max(data, d => d.y);

    let customLabels = {};
    data.forEach(d => {
      customLabels[d.x] = DateTime.fromISO(d.pixel_date).toFormat('LLL');
    });

    const maxDate = d3.max(data, d => d.pixel_date);
    setLatestDate(maxDate);

    data = d3.group(data, d => d.cluster_label, d => d.year);

    // Create a color scale over the years
    const domain = Array.from(data.get('0').keys());
    const currYear = domain.at(-1);
    const prevYear = domain.at(-2);

    const legendData = Array.from(data.get('0'), ([k, v]) => ({
      year: k,
      color: colorScale(currYear, prevYear, k)
    }));

    setLegendData(legendData);


    const c0 = Array.from(data.get('0'), ([k, v]) => ({
      id: k,
      color: colorScale(currYear, prevYear, k),
      data: v
    }));

    const c1 = Array.from(data.get('1'), ([k, v]) => ({
      id: k,
      color: colorScale(currYear, prevYear, k),
      data: v
    }));

    const c2 = Array.from(data.get('2'), ([k, v]) => ({
      id: k,
      color: colorScale(currYear, prevYear, k),
      data: v
    }));

    const result = {
      cluster0: c0,
      cluster1: c1,
      cluster2: c2,
      miny: 0,
      maxy: 100,
      customLabels: customLabels
    };

    return result;

  };

  function cdefChartData(resp) {

    // Parse the response into format for charts.
    let data = resp.map(d => {
      return {
        pixel_date: d.pixel_date,
        catchment: d.catchment,
        cluster_label: d.cluster_label,
        year: d.year,
        hydro_year: d.hydro_year,
        x: d.yday,
        y: d.season == 'W' ? d.cdef_ndsi : null
      };
    });

    data = data.filter(d => d.year > 2000);

    let customLabels = {};
    data.forEach(d => {
      customLabels[d.x] = DateTime.fromISO(d.pixel_date).toFormat('LLL');
    });

    const miny = d3.min(data, d => d.y);
    const maxy = d3.max(data, d => d.y);

    data = d3.group(data, d => d.cluster_label, d => d.year);

    // Create a color scale over the years
    const domain = Array.from(data.get('0').keys());
    const currYear = domain.at(-1);
    const prevYear = domain.at(-2);

    const c0 = Array.from(data.get('0'), ([k, v]) => ({
      id: k,
      color: colorScale(currYear, prevYear, k),
      data: v
    }));

    const c1 = Array.from(data.get('1'), ([k, v]) => ({
      id: k,
      color: colorScale(currYear, prevYear, k),
      data: v
    }));

    const c2 = Array.from(data.get('2'), ([k, v]) => ({
      id: k,
      color: colorScale(currYear, prevYear, k),
      data: v
    }));

    const result = {
      cluster0: c0,
      cluster1: c1,
      cluster2: c2,
      miny: miny,
      maxy: maxy,
      customLabels: customLabels
    };

    return result;

  };


  return (
    <div>

      <div className={styles.flex_container}>
        <div className={styles.flex_child}>
          <p>Catchment</p>
          <HTMLSelect
            options={catchments}
            onChange={e => setCatchment(e.currentTarget.value)}
            value={catchment}
          />
        </div>

        <div className={styles.flex_child}>
          <p>Moving Avg Window Size</p>
          <HTMLSelect
            options={movingAvgWindowSizes}
            onChange={e => setMovingAvgWindowSize(e.currentTarget.value)}
            value={movingAvgWindowSize}
          />
        </div>
      </div>

      <H3>NDSI Charts for {isLoading ? 'Loading...' : catchment}, latest tile date: {latestDate}</H3>

      <div className={styles.legend_container}>
        {legendData.map(d => (
          <div
            key={d.year}
            className={styles.legend_child}
            style={{
              backgroundColor: d.color,
              color: d.color == 'lightgrey' ? 'black' : 'white',
              opacity: d.year === activeLine ? 1 : 0.5
            }}
            onMouseEnter={() => setActiveLine(d.year)}
            onMouseLeave={() => setActiveLine(null)}
          >{d.year}</div>
        ))}
      </div>


      <div className={styles.wrapper}>
        <div className={styles.charts}>

          <div className={styles.chart}>
            <NdsiChart data={ndsiData.cluster2} showBottomAxis={false} customLabels={ndsiData.customLabels} chartTitle='Cluster 2' miny={ndsiData.miny} maxy={ndsiData.maxy} activeLine={activeLine} setActiveLine={setActiveLine}/>
          </div>

          <div className={styles.chart}>
            <NdsiChart data={ndsiData.cluster1} showBottomAxis={false} customLabels={ndsiData.customLabels} chartTitle='Cluster 1' miny={ndsiData.miny} maxy={ndsiData.maxy} activeLine={activeLine} setActiveLine={setActiveLine}/>
          </div>

          <div className={styles.chart}>
            <NdsiChart data={ndsiData.cluster0} showBottomAxis={true} customLabels={ndsiData.customLabels} chartTitle='Cluster 0' miny={ndsiData.miny} maxy={ndsiData.maxy} activeLine={activeLine} setActiveLine={setActiveLine}/>
          </div>

        </div>

        <div className={styles.charts}>

          <div className={styles.chart}>
            <NdsiChart data={cdefData.cluster2} showBottomAxis={false} customLabels={cdefData.customLabels} chartTitle='Cluster 2' miny={cdefData.miny} maxy={cdefData.maxy} activeLine={activeLine} setActiveLine={setActiveLine}/>
          </div>

          <div className={styles.chart}>
            <NdsiChart data={cdefData.cluster1} showBottomAxis={false} customLabels={cdefData.customLabels} chartTitle='Cluster 1' miny={cdefData.miny} maxy={cdefData.maxy} activeLine={activeLine} setActiveLine={setActiveLine}/>
          </div>

          <div className={styles.chart}>
            <NdsiChart data={cdefData.cluster0} showBottomAxis={true} customLabels={cdefData.customLabels} chartTitle='Cluster 0' miny={cdefData.miny} maxy={cdefData.maxy} activeLine={activeLine} setActiveLine={setActiveLine}/>
          </div>

        </div>
      </div>

    </div>
  );

};

export default NdsiChartContainer;
