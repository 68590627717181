import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import { DateTime } from 'luxon';
import { H3 } from '@blueprintjs/core';

import { API } from 'aws-amplify';

import WindDemandBalanceChart from './WindDemandBalanceChart';
import WindDemandBalancePriceChart from './WindDemandBalancePriceChart';
import styles from './WindDemandBalanceContainer.module.css';


const makeAklTimestamp = (t) => {
  return DateTime.fromISO(t).setZone("Pacific/Auckland").toISO({ suppressMilliseconds: true, includeOffset: false })
};


const merge = (prices, data) => {
  const pricesTimeMap = new Map(prices.map(d => [makeAklTimestamp(d.period_timestamp), d.price]));
  const dailyAvgPricesTimeMap = new Map(prices.map(d => [makeAklTimestamp(d.period_timestamp), d.daily_avg_price]));
  const dataTimeMap = new Map(data.map(d => [makeAklTimestamp(d.period_timestamp), makeAklTimestamp(d.period_timestamp_x)]));

  const result = Array.from(dataTimeMap, ([pt, ptx]) => ({
    period_timestamp_x: ptx,
    daily_avg_price: dailyAvgPricesTimeMap.get(ptx),
    x: pt,
    y: pricesTimeMap.get(ptx)
  }));

  const filterResult = result.filter(d => d.y !== undefined);

  return filterResult;
};


const getChartData = (data, prices) => {

  // TODO review and cleanup
  prices = d3.group(prices, d => d.node);

  const ben = merge(prices.get('BEN2201'), data);
  const ota = merge(prices.get('OTA2201'), data);

  const benAvg = ben.map(d => ({
    period_timestamp_x: d.period_timestamp_x,
    x: d.x,
    y: d.daily_avg_price
  }));

  const otaAvg = ota.map(d => ({
    period_timestamp_x: d.period_timestamp_x,
    x: d.x,
    y: d.daily_avg_price
  }));

  prices.delete('BEN2201');
  prices.delete('OTA2201');

  prices.set('BEN', ben);
  prices.set('BEN AVG', benAvg);
  prices.set('OTA', ota);
  prices.set('OTA AVG', otaAvg);

  // For nivo
  prices = Array.from(prices, ([k, v]) => ({
    id: k,
    data: v
  }));

  const [x0, x1] = d3.extent(data, d => d.value);
  const xlim = d3.max([Math.abs(x0), Math.abs(x1)]);

  let nivoData = data.map(d => {
    return {
      series_name: d.variable,
      trading_date: d.trading_date,
      trading_period: d.trading_period,
      x: DateTime.fromISO(d.period_timestamp).setZone("Pacific/Auckland").toISO({ suppressMilliseconds: true, includeOffset: false }),
      y: d.value
    }
  });

  nivoData = d3.group(nivoData, d => d.series_name);

  const colors = {
    'delta_load_forecast_mw': 'green',
    'delta_wind_mw': 'blue',
    'dnow': 'red'
  };

  nivoData = Array.from(nivoData, ([k, v]) => ({
    id: k,
    color: colors[k],
    data: v.filter(d => d.y !== null),
  }));

  return { data: nivoData, prices: prices, xlim: xlim };
}


function WindDemandBalanceContainer(props) {

  const fromDate = DateTime.local({zone: 'Pacific/Auckland'}).minus({days: 7}).toISODate();
  const toDate = DateTime.local({zone: 'Pacific/Auckland'}).plus({days: 13}).toISODate();

  const [data, setData] = useState(null);

  useEffect(() => {
    document.title = `DNoW ${toDate}`;

    async function fetchDnowData() {
      const resp = await API.get('SparcAPI', '/emh/demand_net_of_wind', {
        queryStringParameters: {
          node_region: 'TOTAL',
          model_collection: 'predictwind-ensemble',
          series_name: 'NZL_PWE',
          from_date: fromDate,
          to_date: toDate
        }
      });
      return resp;
    }

    async function fetchPriceData() {
      const nodes = JSON.stringify(['OTA2201', 'BEN2201']);

      const resp = await API.get('SparcAPI', '/nzem/energy_prices', {
        queryStringParameters: {
          nodes: nodes,
          from_date: fromDate,
          to_date: toDate
        }
      });
      return resp;
    }

    Promise.all([fetchDnowData(), fetchPriceData()])
    .then((values) => {
      // Log any errors. API Gateway will add errorMessage, errorType fields.
      values.forEach(d => {
        if ('errorMessage' in d) {
          throw new Error(d['errorMessage']);
        }
      });

      let [dnowData, priceData] = values;

      // If error here will get caught below
      const chartData = getChartData(dnowData, priceData);
      setData(chartData);

    })
    .catch((err) => {
      console.log(err);
      alert('Error fetching data, check console log.')
    });

  }, [fromDate, toDate]);

  return (
    <div>

      <div className={styles.flex_container}>
      </div>

      <div className={styles.charts}>
        <H3>Demand Net of Wind [today to {toDate}]</H3>
        <div className={styles.chart}>
          {data !== null &&
            <WindDemandBalanceChart data={data.data} xlim={data.xlim} />
          }
        </div>

        <div className={styles.chart}>
          {data !== null &&
            <WindDemandBalancePriceChart data={data.prices} />
          }
        </div>

      </div>

    </div>

  );

};

export default WindDemandBalanceContainer;
