import { ResponsiveBar } from '@nivo/bar';


const myLabel = ({ id }) => {
  const label = Math.trunc(Number(id.replace(/[^0-9.-]+/g,"")));
  return label.toLocaleString('en-NZ');
};


const tooltip = ({ id, value, indexValue }) => (
  <div
      style={{
          padding: 5,
          // color,
          background: 'white',
      }}
  >
    <span>Trading Period: {indexValue}</span>
    <br />
    <span><strong>{value} MW @ {id}</strong></span>
  </div>
);


function GenerationOffersChart({ data, keys }) {

  return (
    <ResponsiveBar
      data={data}
      margin={{ top: 40, right: 20, bottom: 40, left: 40 }}
      indexBy='trading_period'
      keys={keys}
      label={myLabel}
      tooltip={tooltip}
      colors={{scheme: 'category10'}}
      animate={false}
    />
  );

};

export default GenerationOffersChart;
