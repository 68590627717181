import React, { useEffect, useReducer } from 'react';
import * as d3 from 'd3';
import { DateTime } from 'luxon';
import { HTMLSelect } from '@blueprintjs/core';
import { DateInput } from "@blueprintjs/datetime";

import { API } from 'aws-amplify';

import GenerationOffersChart from './GenerationOffersChart';
import GenerationStationMultiSelect from './GenerationOffersMultiSelect';
import styles from './GenerationOffersContainer.module.css';


const initialState = {
  data: [],
  oprDate: DateTime.now().setZone("Pacific/Auckland").minus({days: 1}).toJSDate(),
  chartData: { data: [], keys: [], key: null },
  companies: [],
  company: 'MERI',
  stations: [],
  stationSelection: [],
  hasFetched: false
};


const ACTIONS = {
  LOAD_DATA: 'load-data',
  OPR_DATE: 'opr-date',
  COMPANY: 'company',
  STATIONS: 'stations',
};


const getChartData = (data, company, stationSelection) => {
  const filteredData = data.filter(d => d.company === company).filter(d => stationSelection.indexOf(d.station) !== -1);

  const sortedData = [...filteredData].sort((a, b) => a.price - b.price);

  const chartKeysMap = new Map();
  sortedData.forEach(d => {
    chartKeysMap.set(d.price_key, d.price);
  });

  const _keys = [...chartKeysMap.keys()];


  const gdata = d3.rollup(filteredData, v => Math.trunc(d3.sum(v, d => d.power_mw)), d => d.trading_period, d => d.price_key);

  const _data = Array.from(gdata, ([k, v]) => {
    let result = Object.fromEntries(v);
    result['trading_period'] = k;
    return result;
  }).sort((a, b) => a.trading_period - b.trading_period);

  const chartKey = `${data[0].trading_date}-${company}-${stationSelection.join('-')}`;

  return { keys: _keys, data: _data, key: chartKey };
};


const offersReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.LOAD_DATA:
      const companies = [...new Set(action.data.map(d => d.company))];
      const stations = [...new Set(action.data.filter(d => d.company === state.company).map(d => d.station))];
      const stationSelection = [...new Set(action.data.filter(d => d.company === state.company).map(d => d.station))];
      // On initial load state.hasFetched is false, return the default company/station selection
      if (!state.hasFetched) {
        return {
          ...state,
          data: action.data,
          companies: companies,
          stations: stations,
          stationSelection: stationSelection,
          hasFetched: true,
          chartData: getChartData(action.data, state.company, stationSelection)
        };
      }
      // Otherwise, we have done the initial fetch and want to preserve company/station selection
      else {
        return {
          ...state,
          data: action.data,
          chartData: getChartData(action.data, state.company, state.stationSelection)
        };
      }

    case ACTIONS.OPR_DATE:
      //  Changing the oprDate will cause LOAD_DATA to dispatch.
      return {
        ...state,
        oprDate: action.oprDate,
      };

    case ACTIONS.COMPANY:
      // When changing company, default is all stations.
      const defaultStations = [...new Set(state.data.filter(d => d.company === action.company).map(d => d.station))];
      return {
        ...state,
        company: action.company,
        stations: defaultStations,
        stationSelection: defaultStations,
        chartData: getChartData(state.data, action.company, defaultStations)
      };

    case ACTIONS.STATIONS:
      return {
        ...state,
        stationSelection: action.stationSelection,
        chartData: getChartData(state.data, state.company, action.stationSelection)
      };

    default:
      throw new Error();
  }
};


function GenerationOffersContainer(props) {

  const [state, dispatch] = useReducer(offersReducer, initialState);
  const { chartData, oprDate, company, companies, stations, stationSelection } = state;

  useEffect(() => {
    document.title = `Offers ${DateTime.fromJSDate(oprDate).toLocaleString()}`;

    async function fetchData() {
      const opr_date = DateTime.fromJSDate(oprDate).toISODate();
      const resp = await API.get('SparcAPI', '/wits/historic_orders_offers', {
        queryStringParameters: {
          opr_date: opr_date,
        }
      });

      const data = resp.map(d => ({
        ...d,
        price_key: `${Number(d.price).toLocaleString('en-NZ', { style: 'currency', currency: 'NZD' })}`,
        station: `${d.station}-${d.unit}`
      }));

      if (data.length === 0) return;

      dispatch({
        type: ACTIONS.LOAD_DATA,
        data: data,
      });

    };

    fetchData();

  }, [oprDate]);


  const jsDateFormatter = {
    formatDate: date => DateTime.fromJSDate(date).toISODate(),
    parseDate: str => DateTime.fromISO(str),
    placeholder: 'YYYY-MM-DD'
  };


  return (
    <div>
      <div className={styles.flex_container}>

        <div className={styles.flex_child}>
          <p>Trading Date</p>
          <DateInput
            {...jsDateFormatter}
            value={oprDate}
            defaultValue={oprDate}
            onChange={e => dispatch({ type: ACTIONS.OPR_DATE, oprDate: e })}
          />
        </div>

        <div className={styles.flex_child}>
          <p>Company</p>
          <HTMLSelect
            options={companies}
            onChange={e => dispatch({ type: ACTIONS.COMPANY, company: e.currentTarget.value })}
            value={company}
          />
        </div>

        <div className={styles.flex_child}>
          <p>Stations</p>
          <GenerationStationMultiSelect
            items={stations}
            selectedItems={stationSelection}
            dispatch={dispatch}
          />
        </div>

      </div>

      <div className={styles.charts}>
        <h3>Generation Offers for {company} on {DateTime.fromJSDate(oprDate).toLocaleString({...DateTime.DATE_MED, weekday: 'long' })}</h3>
        <div className={styles.chart}>
          <GenerationOffersChart
            key={chartData.key}
            data={chartData.data}
            keys={chartData.keys}
          />
        </div>
      </div>

    </div>

  );

};

export default GenerationOffersContainer;
