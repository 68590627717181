import { DateTime } from 'luxon';
import { ResponsiveLine } from '@nivo/line';

const tooltip = ({ slice }) => {
  const xLabel = slice.points.map(point => point.data.xFormatted)[0];
  const period_timestamp_x = slice.points.map(point => point.data.period_timestamp_x)[0];

  return (
    <div
      style={{
        background: 'white',
        padding: '9px 12px',
        border: '1px solid #ccc',
      }}
    >
      <div>{xLabel} [{period_timestamp_x}]</div>
      {slice.points.map(point => (
        <div
          key={point.id}
          style={{
            color: point.serieColor,
            padding: '3px 0',
          }}
        >
          <strong>{point.serieId}</strong> [{point.data.yFormatted}]
        </div>
      ))}
    </div>
  );
};


function WindDemandBalancePriceChart({ data }) {
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: 'time', format: "%Y-%m-%dT%H:%M:%S", useUTC: false }}
      xFormat="time:%Y-%m-%d %H:%M"
      yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        format: '%a, %d/%m',
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -45,
        legendOffset: 36,
        legendPosition: 'middle'
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'MW',
        legendOffset: -40,
        legendPosition: 'middle'
      }}
      sliceTooltip={tooltip}
      enablePoints={false}
      enableSlices='x'
      colors={{scheme: 'category10'}}
      layers={['grid', 'markers', 'axes', 'areas', 'lines', 'crosshair', 'slices', 'points', 'mesh', 'legends']}
      useMesh={true}
      animate={false}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
    />
  )
};

export default WindDemandBalancePriceChart;
