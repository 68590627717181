import { ResponsiveLine } from '@nivo/line';
import { useTheme as nivoUseTheme } from '@nivo/core'
import { Chip, TableTooltip } from '@nivo/tooltip'


const tooltip = ({ slice, axis }) => {
  const theme = nivoUseTheme();
  const otherAxis = axis === 'x' ? 'y' : 'x'

  const xLabel = slice.points.map(point => point.data.formatted_date)[0];
  // const trading_period = slice.points.map(point => point.data.trading_period)[0];

  return (
    <TableTooltip
        title={xLabel}
        rows={slice.points.map(point => [
            <Chip key="chip" color={point.serieColor} style={theme.tooltip.chip} />,
            point.serieId,
            <span key="value" style={theme.tooltip.tableCellValue}>
                {point.data[`${otherAxis}Formatted`]}
            </span>,
        ])}
    />
  )

};


function GenerationChart({ data }) {
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: 'time', format: "%Y-%m-%dT%H:%M:%S", useUTC: false }}
      xFormat="time:%Y-%m-%d %H:%M"
      yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
          format: '%a %H:%M',
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -45,
          tickValues: 'every 3 hours',
        //   legend: 'Time',
          legendOffset: 36,
          legendPosition: 'middle'
      }}
      axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'MW',
          legendOffset: -40,
          legendPosition: 'middle'
      }}
      sliceTooltip={tooltip}
      enablePoints={false}
      enableSlices='x'
      colors={{ scheme: 'category10' }}
      // layers={['grid', 'axes', 'legends', 'lines', 'slices', 'mesh', 'points', tooltip]}
      useMesh={true}
      animate={false}
      legends={[
          {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                  {
                      on: 'hover',
                      style: {
                          itemBackground: 'rgba(0, 0, 0, .03)',
                          itemOpacity: 1
                      }
                  }
              ]
          }
      ]}
    />
  )
};

export default GenerationChart;
